import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import { CourseAdditionMetaData } from 'types/CourseAdditionMetaData'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormData = require('form-data')

export const UploadCourses: React.FC = () => {
	const ref = useRef<HTMLFormElement>(null)
	const [state, setState] = useState<{ [key: string]: any }>({})
	const [batch, setBatch] = useState<CourseAdditionMetaData>()
	return (
		<>
			<form
				ref={ref}
				onSubmit={async (e) => {
					e.preventDefault()
					const form = new FormData()
					// console.log({ state })
					if (state?.['file']) {
						form.append('file', state?.['file'])
						// console.log({ form })
						try {
							const batch = await handleApi<CourseAdditionMetaData>(
								axios.post(getUrl('course/batch'), form, {
									headers: {
										'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
										Authorization: localStorage.getItem('token'),
									},
								})
							)
							setBatch(batch)
						} catch (err) {
							console.error((err as any)?.response?.data?.message)
							console.error((err as any)?.response)
						}
					}
				}}
			>
				<input
					onChange={(e) => {
						const files = e.target.files
						if (files !== null) {
							setState((state) => ({
								...state,
								file: files[0],
							}))
						}
					}}
					type='file'
					name='file'
					id='file'
				/>
				<input type='submit' value='Submit' />
			</form>
			{batch?.success && (
				<>
					<h1>Success</h1>
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Course Name</th>
								<th scope='col'>Tag</th>
							</tr>
						</thead>
						<tbody>
							{batch?.success.map((elem, index) => (
								<tr key={index}>
									<th>{index + 1}</th>
									<td>
										<Link to={`/admin/course/${elem.uid}`}>{elem.name}</Link>
									</td>
									<td>{elem.course_tag}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}

			{batch?.failed && (
				<>
					<h1>Errors</h1>
					<table className='table'>
						<thead>
							<tr>
								<th scope='col'>#</th>
								<th scope='col'>Course Name</th>
								<th scope='col'>Error</th>
							</tr>
						</thead>
						<tbody>
							{batch?.failed.map((elem, index) => (
								<tr key={index}>
									<th>{index + 1}</th>
									<td>{elem.name}</td>
									<td style={{ color: 'red', fontWeight: 'bold' }}>{elem.error}</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}
		</>
	)
}
