import { UploadCourses } from 'components/admin/UploadCourses'

import React from 'react'

const BatchAddUser: React.FC = () => {
	return (
		<>
			<h1>Add Courses CSV</h1>

			<UploadCourses></UploadCourses>
		</>
	)
}
export default BatchAddUser
